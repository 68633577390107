import { Component, OnInit } from '@angular/core';
import { CRUD_MODE, mapOptions, mapTypes } from '@enums/enum';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { catchError, tap, map, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AppService } from '@services/app.service';
import { Observable, of } from 'rxjs';
import { Depot } from '@interfaces/depot.interface';
import { DepotService } from '../../services/depot.service';
import { Address } from '@entities/address';
import { Coordinates } from '@entities/coordinates';
import * as _ from 'lodash';
import { Additional } from '@entities/additional';
import { plainToClass } from 'class-transformer';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';
import * as ICoords from '@interfaces/coordinates.interface';

@Component({
  selector: 'app-depot-edit',
  templateUrl: './depot-edit.component.html',
  styleUrls: ['./depot-edit.component.scss']
})
export class DepotEditComponent implements OnInit {

  public depot$: Observable<Depot | undefined>;
  public mode: CRUD_MODE;

  public form: FormGroup;
  public loader: boolean = true;
  public submitted: boolean = false;

  public address: AbstractControl;
  public locationAdditional: AbstractControl;
  public coordinates: AbstractControl;
  public testLocationsControl: FormArray;
  public allowedZipCodesControl: FormArray;
  public disallowedZipCodesControl: FormArray;

  public coordinatesRaw: ICoords.Coordinates = {lat: 52.22, lng: 21.016};

  get addressKeys() {
    const address = new Address();
    return _.keys(address);
  }

  get coordinatesKeys() {
    const cords = new Coordinates();
    return _.keys(cords);
  }

  get additionalKeys() {
    const cords = new Additional();
    return _.keys(cords);
  }

  public mapOption = mapOptions.REGULAR;
  public mapProperties = {
    zoom: 18,
    mapTypeId: mapTypes.ROADMAP
  };

  public formErrorsMessages

  constructor(
    private formBuilder: FormBuilder,
    private notifierService: NotifierService,
    private translateService: TranslateService,
    private router: Router,
    private appService: AppService,
    private route: ActivatedRoute,
    private readonly depotService: DepotService
  ) { }

  public ngOnInit() {
    
    this.depot$ = this.route.params.pipe(
      map(params => {
        this.mode = location.href.includes('create') ? CRUD_MODE.CREATE : CRUD_MODE.UPDATE;
        return params;
      }),
      switchMap((params) => {
        return (this.mode === CRUD_MODE.CREATE)
          ? of(undefined)
          : this.depotService.findOne(params['depotId'])
      }),
      tap((config) => this.initForm(config))
    )
  }

  private initForm(depot: Depot | undefined) {

    console.log(depot);

    this.form = this.formBuilder.group({

      code: [null, [Validators.required, Validators.maxLength(32)]],
      id: [null],
      name: [null, [Validators.required, Validators.maxLength(255)]],
      version: [null],
      allowedZipCodes: this.formBuilder.array([]),
      disallowedZipCodes: this.formBuilder.array([]),
      address: this.formBuilder.group({
        country: ['Polska', [Validators.required, Validators.maxLength(255)]],
        district: [null, [Validators.required, Validators.maxLength(255)]],
        houseNO: [null, [Validators.required, Validators.maxLength(255)]],
        street: [null, [Validators.required, Validators.maxLength(255)]],
        town: [null, [Validators.required, Validators.maxLength(255)]],
        voivoidship: [null, [Validators.required, Validators.maxLength(255)]],
        zip: [null, [Validators.required, Validators.pattern('[0-9]{2}\-[0-9]{3}')]]
      }),
      locationAdditional: this.formBuilder.group({
        companyName: [null, [Validators.maxLength(255)]],
        flatNO: [null, [Validators.maxLength(255)]],
        lift: [null, [Validators.maxLength(255)]],
        staircaseNO: [null, [Validators.maxLength(255)]],
        floor: [null, [Validators.maxLength(255)]],
        contactName: [null, [Validators.maxLength(255)]],
        contactPhoneNO: [null, [Validators.maxLength(255)]],
        domofonCode: [null, [Validators.maxLength(255)]],
        instructions: [null, [Validators.maxLength(255)]],
        contactEmail: [null, [Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')]]
      }),
      locationCoordinates: this.formBuilder.group({
        lat: [null, Validators.required],
        lng: [null, Validators.required]
      }),
      testLocations: this.formBuilder.array([])
    });

    this.address = this.form.controls[ 'address' ];
    this.locationAdditional = this.form.controls[ 'locationAdditional' ];
    this.coordinates = this.form.controls[ 'locationCoordinates' ];
    this.testLocationsControl = <FormArray>this.form.controls['testLocations'];
    this.allowedZipCodesControl = <FormArray>this.form.controls['allowedZipCodes'];
    this.disallowedZipCodesControl = <FormArray>this.form.controls['disallowedZipCodes'];


    if (depot && depot.testLocations && depot.testLocations.length) {
      depot.testLocations.forEach((item) => this.testLocationsControl.push(this.patchValues(item)));
    }

    if (depot && depot.locationAdditional === null) {
      delete depot.locationAdditional;
    }


    if (depot) {
      depot.allowedZipCodes.forEach((code) => this.allowedZipCodesControl.push(this.patchValuesZipCodes(code)));
      depot.disallowedZipCodes.forEach((code) => this.disallowedZipCodesControl.push(this.patchValuesZipCodes(code)));
    }


    if (depot) {
      this.form.patchValue(depot);
      this.address.patchValue(depot.address !== null ? depot.address : {});
      this.locationAdditional.patchValue(depot.locationAdditional ? depot.locationAdditional : {});
      if (depot.locationCoordinates !== null) {
        this.coordinatesRaw = depot.locationCoordinates;
        this.coordinates.patchValue((depot.locationCoordinates !== null) ? depot.locationCoordinates : {});
      }
    }

    console.log(this.form);

    this.loader = false;
  }

  private patchValuesZipCodes(item?: string): AbstractControl {
    return this.formBuilder.control((item) ? item : '', Validators.required);
  }

  private patchValues(item?: (ICoords.Coordinates)): AbstractControl {
    return this.formBuilder.group({
      lat: [item ? item.lat : 52 ],
      lng: [item ? item.lng : 21 ],
    })
  }

  public onSubmit() {
    const formData: Depot = this.form.getRawValue();
    formData['code'] = formData['code'].trim();

    if (this.mode === CRUD_MODE.CREATE) {
      this.create(formData);
    } else {
      this.update(formData);
    }
  }

  private create(raw): void {
    this.depotService.save(raw)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (depoty: Depot) => {
          this.notifierService.notify('success', this.translateService.instant('New depot has been created!'));
          this.appService.clearCache();
          this.router.navigate(['/locations/depots'], { queryParams: { modifiedRow: depoty.id } });
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.formErrorsMessages = error;
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  private update(raw): void {

    this.depotService.update(raw['id'], raw)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (depoty: Depot) => {
          this.notifierService.notify('success', this.translateService.instant('The depot has been updated!'));
          this.appService.clearCache();
          this.router.navigate(['/locations/depots'], { queryParams: { modifiedRow: depoty.id } });
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.formErrorsMessages = error;
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }

  public getCoordinates(): void {
    const address: Address = plainToClass(Address, this.form.getRawValue()['address']);
    this.depotService.getCoordinatesForLocation(address.raw).subscribe(
      (coords: Coordinates) => {
        this.coordinatesRaw = coords;
        this.coordinates.patchValue(coords.serialize());
      }
    );
  }

  public removeAllowedZipCodes(index: number) {
    this.allowedZipCodesControl.removeAt(index);
  }

  public removeDisallowedZipCodes(index: number) {
    this.disallowedZipCodesControl.removeAt(index);
  }

  public addAllowedZipCodes() {
    this.allowedZipCodesControl.push(this.patchValuesZipCodes());
  }

  public addDisallowedZipCodes() {
    this.disallowedZipCodesControl.push(this.patchValuesZipCodes());
  }

  public placeMarker(event) {
    this.testLocationsControl.push(this.patchValues({lat: event.coords.lat, lng: event.coords.lng}));
  }

  public removeMarker(marker, index) {
    this.testLocationsControl.removeAt(index);
  }

  public changeCoordinates(coordinates: Coordinates): void {
    this.coordinates.patchValue(coordinates.serialize());
  }

  public tooltipLabel(property: string): string {
    return tooltipLabel(this.translateService, `tooltips.depot.labels.${property}`);
  }

}
