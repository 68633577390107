import { Type, plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Driver } from '@drivers/entities/driver';
import { Box } from '@entities/box';
import { Delivery } from '@entities/delivery';
import { DeliveryClean } from '@entities/delivery-clean';
import { Entity } from '@entities/Entity';
import { RouteId } from '@entities/routeId';
import { Serializable } from '@entities/Serializable';
import { ReviewStatus, RouteGeneratedBy, RouteStatus, ActivityType, RouteType } from '@enums/enum';
import { Van } from '@hardware/entities/van';
import { serializeType } from '@shared/functions/serializeType.function';
import { Activity } from './activity';
import { Location } from './location';
import { RoutePunctuality } from '@interfaces/route-punctuality.interface';

export class Route extends Entity implements Serializable<Route> {
    @Type(serializeType(Van)) public van: Van = null;
    @Type(serializeType(Driver)) public driver: Driver = null;

    public id: string = null;
    public date: string = null;
    public year: string = null;
    public month: string = null;
    public day: string = null;
    public shift: string = null;
    public routeNumber: number = null;
    public generatedBy: RouteGeneratedBy = RouteGeneratedBy.IMPORT;
    public warehouseId: string;
    public deliveriesCount = 0;
    public boxesCount = 0;
    public status: string = null;
    public type: RouteType;

    get routeId(): RouteId {
        return RouteId.of(this.id, this.date, this.year, this.month, this.day, this.shift, this.routeNumber, this.warehouseId);
    }

    public deserialize(data: any) {
        this.id = _.get(data, 'id');
        this.date = _.get(data, 'date');
        [this.year, this.month, this.day] = this.date.split("-");
        this.shift = _.get(data, 'shift'); // todo lw: remove Morning fallback
        this.warehouseId = _.get(data, 'warehouseId');
        this.van = (new Van()).deserialize(data.van);
        this.driver = (new Driver()).deserialize(data.driver);
        this.generatedBy = (<any>RouteGeneratedBy)[_.get(data, 'generatedBy', RouteGeneratedBy.IMPORT)];
        this.routeNumber = _.get(data, 'routeNumber', '');
        this.status = _.get(data, 'status', '');
        this.van = (new Van()).deserialize(data.van);
        this.driver = (new Driver()).deserialize(data.driver);
        this.type = _.get(data, 'type', '');

        return this;
    }
}

export class RouteExt extends Route implements Serializable<RouteExt> {
    public activities: Activity[];
    public allBoxes = [];
    public allBags = [];
    public allowBoxMerging: boolean;
    public allowJITMerging: boolean;
    public boxesCountLabel: string;
    public loadedBoxed;
    public cardPayments: number;
    public cashPayments: number;
    public couponPayments: number;
    public deliveryDict: { [key: string]: Delivery; } = {};
    public locationDict: { [key: string]: Location; } = {};
    public deliveryCleanList: DeliveryClean[] = [];
    public driverLabel: string;
    public icon: string;
    public index: number;
    public label: string = '';
    public vanLabel: string;
    public plannedDepartureTime;
    public warehouseDepartureTime: string;
    public devicePhoneNumber: string;
    public travelDistance: number;
    public travelTime: number;
    public runtime: number;
    public lockDeliveryOrder: boolean;
    public enableLocationEvents: boolean;
    public scanAfterReturn: boolean;
    public scanBeforeDeparture: boolean;
    public departureScan: string;
    public returnScan: string;

    get dateLabel() {
        return moment(this.date).format('DD MMMM YYYY');
    }

    public deserialize(data: any) {

        super.deserialize(data);

        // todo remove icon path
        const assetsPath = '/assets/img';
        const markerPath = `${assetsPath}/markers`;
        this.routeNumber = _.get(data, 'routeNumber');
        this.generatedBy = (<any>RouteGeneratedBy)[_.get(data, 'generatedBy', RouteGeneratedBy.IMPORT)];

        this.cardPayments = _.get(data, 'cardPayments');
        this.cashPayments = _.get(data, 'cashPayments');
        this.couponPayments = _.get(data, 'couponPayments');
        this.travelDistance = _.get(data, 'travelDistance');
        this.travelTime = _.get(data, 'travelTime');
        this.runtime = _.get(data, 'runtime');
        this.devicePhoneNumber = _.get(data, 'devicePhoneNumber');
        this.allowBoxMerging = _.get(data, 'allowBoxMerging');
        this.allowJITMerging = _.get(data, 'allowJITMerging');
        this.lockDeliveryOrder = _.get(data, 'lockDeliveryOrder');
        this.enableLocationEvents = _.get(data, 'enableLocationEvents');
        this.scanAfterReturn = _.get(data, 'scanAfterReturn');
        this.scanBeforeDeparture = _.get(data, 'scanBeforeDeparture');
        this.departureScan = _.get(data, 'departureScan');
        this.returnScan = _.get(data, 'returnScan');

        this.id = _.get(data, 'id');
        this.date = _.get(data, 'date');
        this.warehouseId = _.get(data, 'warehouse');
        [this.year, this.month, this.day] = this.date.split("-");

        this.activities = plainToClass(Activity, _.get(data, 'activities') as Activity[]);
        
        // todo ref use van.label
        this.vanLabel = 'Van ' + this.routeNumber;

        this.icon = `${markerPath}/v${Number(this.routeNumber) % 7}.png`;

        this.driverLabel = 'Jan Kowalski';
        this.label = String.fromCharCode(((Number(this.routeNumber) % 26)) + 65);

        this.deliveryCleanList = _.get(data, 'deliveryListClean', []).map((deliveryCleanData: any) => {
                try {    
                    return new DeliveryClean().deserialize(deliveryCleanData);
                } catch (err) {
                    throw err;
                }
            }
        );
        
        for (const [key, deliveryRaw] of Object.entries(_.get(data, 'deliveryDict'))) {
            
            let delivery: Delivery;
            
            try {
                delivery = (new Delivery()).deserialize(deliveryRaw);
            } catch (err) {
                console.error(err);
            }
            
            this.boxesCount += delivery.order.boxes.length;
            this.allBoxes.push(...delivery.order.boxes);
            delivery.order.boxes.forEach((box: Box) => this.allBags.push(...box.bags));
            this.deliveryDict[delivery.id] = delivery;
        }

        for (const [key, locationRaw] of Object.entries(_.get(data, 'locationDict'))) {
            
            let location: Location;
            
            try {
                location = (new Location()).deserialize(locationRaw);
            } catch (err) {
                console.error(err);
            }
            
            this.locationDict[location.id] = location;
        }

        let deliveryIndex = 0;
        this.activities.map((a: Activity) => {
            if (a.idList.length && a.type === ActivityType.DELIVERY) {
                _.forEach(a.idList, (deliveryId: string) => {
                    const d = this.deliveryDict[deliveryId];
                    if (d !== undefined) {
                        deliveryIndex += 1;
                        const delivery = this.deliveryDict[deliveryId];
                        delivery.deliveryIndex = deliveryIndex;
                        a.deliveries.push(delivery);
                    }
                })
            }
        });

        //this.allBoxes = _.uniqBy(this.allBoxes, (box: Box) => box.id);
        _.forEach(this.activities.filter((a: Activity) => a.type === ActivityType.DELIVERY), (a: Activity) =>  this.deliveriesCount += a.idList.length);
        this.loadedBoxed = _.countBy(this.allBoxes, (box: Box) => box.isLoaded || box.isMerged);
        const boxesCountByType = _.countBy(this.allBoxes, (box: Box) => box.type);
        this.boxesCountLabel = `(${boxesCountByType.DRY || 0}/${boxesCountByType.CHILLED || 0}/${boxesCountByType.FROZEN || 0})`; 
        this.plannedDepartureTime = _.get(data, 'plannedDeparture');
        this.warehouseDepartureTime = moment(this.plannedDepartureTime).add(-5, 'minutes').format('HH:mm')

        return this;
    }

    public serialize() {
        return {};
    }

    public isStatusDone(): boolean {
        return this.status === RouteStatus.FINISHED;
    }

    public isStatusCompleted(): boolean {
        return this.status === RouteStatus.COMPLETED;
    }

    public isStatusInProgress(): boolean {
        return this.status === RouteStatus.INPROGRESS;
    }

    public isStatusNotCompleted(): boolean {
        return this.status === RouteStatus.NOTCOMPLETED;
    }

    public countDeliveriesByStatus() {
        return _.countBy(this.deliveryDict, (d: Delivery) => d.status);
    }

    get isManual() {
        return this.generatedBy === RouteGeneratedBy.MANUAL;
    }

    get sumDryBoxes(): number {
        let sum = 0
        this.activities.map((a: Activity) => {
            if (a.type === ActivityType.DELIVERY) {
                sum +=  a.requiredCapacity.dryBoxes
            }
        });
        return sum;
    }

    get sumChilledBoxes(): number {
        let sum = 0
        this.activities.map((a: Activity) => {
            if (a.type === ActivityType.DELIVERY) {
                sum +=  a.requiredCapacity.chilledBoxes
            }
        });
        return sum;
    }

    get sumChilledFrozen(): number {
        let sum = 0
        this.activities.map((a: Activity) => {
            if (a.type === ActivityType.DELIVERY) {
                sum +=  a.requiredCapacity.frozenBoxes
            }
        });
        return sum;
    }

    get sumAllBoxes(): number {
        return this.sumDryBoxes + this.sumChilledBoxes;
    }

    get routeLabel(): string {
        return `${this.date}:${this.warehouseId}:${this.routeNumber}`;
    }
}

export class RouteSummary extends Route implements Serializable<RouteSummary> {

    public deliveriesDone: number = null;
    public reviewStatus: ReviewStatus = null;
    public reviewComment: string = null;
    public cardPayments: number = null;
    public cashPayments: number = null;
    public couponPayments: number = null;
    public amountDue: number = null;
    public accountPayments: number = 0;
    public deferredPayments: number = 0;
    public totalPayments: number = 0;
    public totalComplaints: number = 0;
    public wirePayments: number = 0;
    public dryBoxes: number = 0;
    public chilledBoxes: number = 0;
    public frozenBoxes: number = 0;
    public breadBoxes: number = 0;
    public coolomatDeliveries: number = 0;
    public vehicleTypeCode: string = '';
    public travelTime: number = 0;
    public travelDistance: number = 0;
    public punctuality: RoutePunctuality
    public virtualDepot: string;


    public static filterByWarehouse(routes: RouteSummary[]) {
        return _.filter(routes, (e: RouteSummary) => e.warehouseId === localStorage.getItem('depot'));
    }

    public deserialize(data: any) {
        super.deserialize(data);

        this.boxesCount = _.get(data, 'boxes', '');
        this.deliveriesCount = _.get(data, 'deliveries', '');
        this.deliveriesDone = _.get(data, 'deliveriesDone', '');
        this.reviewStatus = _.get(data, 'reviewStatus');
        this.cardPayments = _.get(data, 'cardPayments');
        this.cashPayments = _.get(data, 'cashPayments');
        this.couponPayments = _.get(data, 'couponPayments');
        this.amountDue = _.get(data, 'amountDue');
        this.reviewComment = _.get(data, 'reviewComment');
        this.accountPayments = _.get(data, 'accountPayments');
        this.deferredPayments = _.get(data, 'deferredPayments');
        this.coolomatDeliveries = _.get(data, 'coolomatDeliveries', 0);
        this.totalPayments = _.get(data, 'totalPayments', 0);
        this.wirePayments = _.get(data, 'wirePayments');
        this.totalComplaints = _.get(data, 'totalComplaints', 0);
        this.dryBoxes = _.get(data, 'dryBoxes', 0);
        this.chilledBoxes = _.get(data, 'chilledBoxes', 0);
        this.frozenBoxes = _.get(data, 'frozenBoxes', 0);
        this.breadBoxes = _.get(data, 'breadBoxes', 0);
        this.vehicleTypeCode = _.get(data, 'vehicleTypeCode', '');
        this.travelTime = _.get(data, 'travelTime', 0);
        this.travelDistance = _.get(data, 'travelDistance', 0);
        this.punctuality = _.get(data, 'punctuality', 0);
        this.virtualDepot = _.get(data, 'virtualDepot', 0);

        return this;
    }

    public serialize() {
        return {};
    }

    get statusClass() {
        const clazz: string[] = ['badge'];

        switch (this.status) {
            case RouteStatus.INPROGRESS:
                clazz.push('badge-warning');
                break;
            case RouteStatus.COMPLETED:
                clazz.push('badge-success');
                break;
            case RouteStatus.NOTCOMPLETED:
                clazz.push('badge-danger');
                break;
            case RouteStatus.INLOADING:
                clazz.push('badge-secondary');
                break;
            case RouteStatus.FINALIZED:
                clazz.push('badge-info');
                break;
            case RouteStatus.FINISHED:
                clazz.push('badge-info');
                break;
            default:
                clazz.push('badge-light');
        }

        return clazz.join(' ');
    }

    get splitBoxesLabel(): string {
        return `${this.dryBoxes}/${this.chilledBoxes}/${this.frozenBoxes}/${this.breadBoxes}`;
    }
}
