<div class="bars">
    <div class="route slots-bg">
        <div class="bar-legend"></div>
        <div class="bars__bar">
            <div [style.flex]="16" class="bars__bar__activity no-border">
                <div *ngFor="let i of (shift | shiftWorkingHoursPipe); let index = index" [style.flex]="1"
                    class="slot__line text-center">
                    <div class="slot__container">
                        <div class="slot__inner-wrapper">
                            <button (click)="openMapGroupedBySlot(i, mapSlotsTemplate)"
                                class="slot__map-button btn btn-xs btn-white">
                                <i-feather name="map-pin" class="slot__map-icon"></i-feather>
                            </button>
                            <label class="slot__label">{{ i }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bar-stats"></div>
    </div>

    <div *ngFor="let route of routesPlanningResult.routes; let index = index" class="route route-line">
        <!-- <ng-container *ngIf="route.deliveriesCount"> -->
        <div class="bar-legend" [ngClass]="{'tx-brand-01': route.status === 'INFULFILLMENT'}">
            <strong (click)="openMap(route, mapTemplate)">{{ route.routeNumber }}</strong>
        </div>

        <ng-container *ngTemplateOutlet="bar; context: { route: route }"></ng-container>

        <div class="bar-stats">
            <div class="bars__wrapper">
                <button id="visualiser-bar-route-map" type="button" class="route__button btn btn-xs btn-white"
                    (click)="openMap(route, mapTemplate)">
                    <i-feather name="map-pin" class="slot__map-icon"></i-feather>
                </button>
                <button id="visualiser-bar-route-map" type="button" class="route__button btn btn-xs btn-white"
                    (click)="goToRoute(route.routeId)">
                    <i-feather name="eye" class="slot__map-icon"></i-feather>
                </button>
                <!-- <div id="visualiser-menu" class="dropdown" appCheckPrivileges
                    [permissions]="[Permissions.ROLE_SYSADMIN]" dropdown>
                    <button class="route__button btn btn-xs btn-white dropdown-toggle" type="button"
                        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false"></button>
                    <div class="dropdown-menu dropdown-menu-right div-main-actions"
                        aria-labelledby="dropdownMenuButton">
                        <a id="visualiser-menu-go-to-route"
                            class="btn btn-outline-secondary btn-sm dropdown-item btn-uppercase"
                            (click)="goToRoute(route.routeId)">{{ 'Go to route' | translate }}</a>
                        <a id="visualiser-menu-reflow"
                            class="btn btn-outline-secondary btn-sm dropdown-item btn-uppercase"
                            (click)="reflow(route.id)">{{ 'Reflow' | translate }}</a>
                        <a id="visualiser-menu-replan"
                            class="btn btn-outline-secondary btn-sm dropdown-item btn-uppercase"
                            (click)="replan(route.id)">{{ 'Replan' | translate }}</a>
                    </div>
                </div> -->
            </div>
            <span *ngIf="distances" [containerClass]="'pretty-tooltip'"
                [tooltip]="route.routeNumber | distanceLabelPipe:distances[route.runtime]" [style.width.px]="65"
                [style.margin-right.px]="15" placement="left" class="custom-tooltip">
                <strong>{{ route.deliveriesCount }}</strong>&nbsp;<span [style.font-size.px]="10">({{ route.sumAllBoxes
                    }}: {{ route.sumDryBoxes }}, {{ route.sumChilledBoxes }})</span>
            </span>
        </div>
        <!-- </ng-container> -->
    </div>
</div>

<div class="d-flex justify-content-between align-items-baseline mg-l-20 mg-r-20">

    <div id="visualiser-maps" class="maps d-flex">
        <!-- <button (click)="openShiftMap('HEATMAP', shiftMap)" class="btn btn-sm btn-uppercase btn-white">HEATMAP</button> -->
        <button (click)="openShiftMap('CLUSTER', shiftMap)"
            class="btn btn-sm btn-uppercase btn-white mg-r-20 mg-l-20">CLUSTER MAP</button>
    </div>

    <div id="visualiser-legend" class="legend text-right">
        <div class="legend__square legend__square--delivery-time"></div>
        {{ 'Delivery' | translate }}
        <div class="legend__square legend__square--delivery-early-time"></div>
        {{ 'Early delivered' | translate }}
        <div class="legend__square legend__square--delivery-late-time"></div>
        {{ 'Late delivered' | translate }}
        <div class="legend__square legend__square--driving-time"></div>
        {{ 'Driving' | translate }}
        <div class="legend__square legend__square--wait-time"></div>
        {{ 'Wait' | translate }}
        <div class="legend__square legend__square--reload"></div>
        {{ 'Reload' | translate }}
    </div>

</div>

<ng-container *ngIf="!loader && routesPlanningResult.countByslot">

    <foldable-container [class]="'card-body'" [toggleIcon]="true" [showFoldedTitle]="true" [isFolded]="true">
        <div class="title">
            <p class="tx-12 tx-spacing-1 tx-bold tx-color-01 tx-uppercase tx-sans mg-t-10 mg-b-20">
                <span>{{'Solutions details' | translate}}</span>
            </p>
        </div>

        <div class="content">
            <div class="d-flex">
                <div class="mg-r-60">
                    <span
                        class="tx-11 tx-normal tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-t-10 mg-b-20 mg-r-10">{{
                        'Count deliveries by slot' | translate }}:</span>
                    <ol>
                        <li *ngFor="let item of routesPlanningResult.countByslot | keyvalue">{{ item.key }} - <strong
                                [ngClass]="{'red': deliveriesOutOfLimit(item.key, item.value)}">{{ item.value
                                }}</strong></li>
                    </ol>
                </div>
                <div *ngIf="shiftStatusChanged.length">
                    <span
                        class="tx-11 tx-normal tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-t-10 mg-b-20 mg-r-10">{{
                        'Shift statuses history' | translate }}:</span>
                    <ol>
                        <li *ngFor="let event of shiftStatusChanged">{{event.data.previousStatus}} <i-feather
                                name="arrow-right" class="slot__map-icon"></i-feather> {{event.data.status}}:
                            {{event.occurredAt | utcToLocalTimePipe | amLocale: locale | amDateFormat: 'D MMMM YYYY,
                            HH:mm:ss'}}</li>
                    </ol>
                </div>
                <div *ngIf="routesPlanningResult.log" appCheckPrivileges [permissions]="[Permissions.ROLE_SYSADMIN]">
                    <span
                        class="tx-11 tx-normal tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-t-10 mg-b-20 mg-r-10">{{
                        'Optimization log' | translate }}:</span>
                    <ol>
                        <li *ngFor="let logLine of routesPlanningResult.log">{{ logLine }}</li>
                    </ol>
                </div>
            </div>
        </div>
    </foldable-container>
</ng-container>



<ng-template #bar let-route="route">
    <div id="visualiser-bar" class="bars__bar bar" [ngClass]="{'delivery-management-mode' : isDeliveryManagementMode}">
        <ng-container *ngFor="let activity of route.activities; let deliveryIndex = index">
            <div class="bars__bar__activity activity WAIT" *ngIf="route.activities | findWaitingTimePipe:deliveryIndex"
                [style.height.px]="12" [style.flex]="route.activities | findWaitingTimePipe:deliveryIndex"
                tooltip="{{'Waiting time' | translate}}"></div>
            <div class="bars__bar__activity activity test {{activity.type}}"
                [ngClass]="{ 'max-no-boxes-exceeded': activity.requiredCapacity | maxNoBoxesExceededPipe:activity.type, 'crazy-delivery': route | isCrazyDeliveryPipe:activity:deliveryManagmentSettings }"
                [style.height.px]="12" [style.flex]="activity.durationSeconds"
                [style.border-bottom]="activity | activityLateEarlyMarkPipe" [containerClass]="'pretty-tooltip'"
                (click)="findClosestDeliveries(route, activity)" [tooltip]="activity | deliveryTooltipPipe"></div>
        </ng-container>
        <div class="bars__bar__activity TIME_TO_END_SHIFT" [style.height.px]="12"
            [style.flex]="route | endShiftPipe:shift" [containerClass]="'pretty-tooltip'"
            [tooltip]="route | endShiftPaddingPipe:shift">
        </div>
    </div>
</ng-template>

<ng-template #mapTemplate>
    <div class="modal-header" *ngIf="path">
        <h4 class="modal-title pull-left">Route: {{ route.routeNumber }}</h4>
        <a class="btn btn-xs btn-white btn-uppercase go-to-g-maps" target="_blank" [href]="goToGMaps(path)">{{ 'Go to
            google maps' | translate }}</a>

        <button type="button" class="close pull-right" aria-label="Close" (click)="mapTemplateRef.hide()">
            <i-feather name="X"></i-feather>
        </button>
    </div>

    <div class="modal-body">
        <div class="bars" [style.margin.px]="0" [style.top.px]="0">
            <ng-container *ngTemplateOutlet="bar; context: { route: route }"></ng-container>
        </div>
        <agm-map *ngIf="path" [zoomControl]="true" [zoom]="zoom" [latitude]="path.latCenter"
            [longitude]="path.lngCenter" [usePanning]="true">
            <agm-polyline [strokeColor]="000000" strokeWeight="1" [strokeOpacity]="0.5">
                <!-- Warehouse Frisco Marker -->
                <ng-container *ngIf="path">
                    <agm-polyline-point *ngFor="let point of path.points" [latitude]="point.lat"
                        [longitude]="point.lng"> </agm-polyline-point>
                </ng-container>
            </agm-polyline>

            <ng-container *ngIf="client === inpostClient">
                <ng-container *ngIf="path">
                    <agm-marker-cluster
                        imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
                        <agm-marker *ngFor="let point of path.points" iconUrl="{{ markerPath }}/{{ point.icon }}"
                            [latitude]="point.lat" [longitude]="point.lng" [label]="point.label" [title]="point.slot"
                            (markerClick)="isDeliveryManagementMode ? findClosestDeliveriesFromModal(route, point) : point.isOpen = !point.isOpen">
                            <agm-info-window [isOpen]="point.isOpen">{{ point.description }}</agm-info-window>
                        </agm-marker>
                    </agm-marker-cluster>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="client === friscoClient">
                <ng-container *ngIf="path">
                    <agm-marker *ngFor="let point of path.points" iconUrl="{{ markerPath }}/{{ point.icon }}"
                        [latitude]="point.lat" [longitude]="point.lng" [label]="point.label" [title]="point.slot"
                        (markerClick)="isDeliveryManagementMode ? findClosestDeliveriesFromModal(route, point) : point.isOpen = !point.isOpen">
                        <agm-info-window [isOpen]="point.isOpen">{{ point.description }}</agm-info-window>
                    </agm-marker>
                </ng-container>
            </ng-container>



        </agm-map>
    </div>
</ng-template>

<ng-template #mapSlotsTemplate>
    <div class="modal-header" *ngIf="paths.length">
        <h4 class="modal-title pull-left">Slot: {{ selectedSlot }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="mapTemplateRef.hide()">
            <i-feather name="X"></i-feather>
        </button>
    </div>
    <div class="modal-body">
        <gmap [locations]="paths" [mapProperties]="mapProperties" [mapOption]="mapOption"></gmap>
    </div>
</ng-template>

<ng-template #shiftMap>
    <div class="modal-header" *ngIf="paths.length">
        <h4 class="modal-title pull-left">{{ 'Shift' | translate }} : {{ shift.date }} {{ shift.type | translate }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="mapTemplateRef.hide()">
            <i-feather name="X"></i-feather>
        </button>
    </div>
    <div class="modal-body">
        <gmap [locations]="paths" [mapProperties]="mapProperties" [mapOption]="mapOption"></gmap>
    </div>
</ng-template>

<ng-template #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'Confirm action' | translate }}</h4>
    </div>

    <div class="modal-body text-center">
        <p>
            <strong>Do you confirm {{ modalArgs.action | translate }} for route {{ modalArgs.route }}?</strong>
        </p>
        <button type="button" class="btn btn-brand-01" (click)="confirm()">{{ 'Confirm' | translate }}</button>
        <button type="button" class="btn btn-default" (click)="decline()">{{ 'Cancel' | translate }}</button>
    </div>
</ng-template>

<ng-template #crazyDeliveryWindow>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'Manual delivery management' | translate }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="mapTemplateRef.hide()">
            <i-feather name="X"></i-feather>
        </button>
    </div>

    <div class="modal-body text-left modal-crazy-delivery">
        <div class="row">
            <div class="col">
                <label
                    class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 text-left d-block">{{'Target
                    delivery' | translate}}</label>
            </div>
            <div class="col d-flex justify-content-between">
                <label
                    class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 text-left d-block">{{'Nearest
                    delivery' | translate}} ({{closeDeliveryIndex + 1}} z {{numberOfCrazyDeliveries}})</label>
                <div class="nav">
                    <button (click)="closeDeliveryIndexFun(-1)" class="btn btn-xs btn-white"><i-feather class="mg-t-4"
                            name="arrow-left"></i-feather></button>
                    <button (click)="closeDeliveryIndexFun(1)" class="btn btn-xs btn-white"><i-feather class="mg-t-4"
                            name="arrow-right"></i-feather></button>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <label class="tx-sans tx-10 tx-normal tx-uppercase tx-color-03 tx-spacing-1">{{'Customer' |
                    translate}}</label>
                <div>{{selectedCrazyDelivery.delivery?.customer.label}} |
                    {{selectedCrazyDelivery.delivery?.customer.status}} </div>
                <div>{{selectedCrazyDelivery.delivery?.orderId}}</div>

                <label class="tx-sans tx-10 tx-normal tx-uppercase tx-color-03 tx-spacing-1 mg-t-10">{{'Location' |
                    translate}}</label>
                <div>{{selectedCrazyDelivery.delivery?.location.rawAddress}}</div>
                <div>{{selectedCrazyDelivery.delivery?.location.coordinates.parseCoordinates}}</div>
            </div>

            <div class="col">
                <label class="tx-sans tx-10 tx-normal tx-uppercase tx-color-03 tx-spacing-1">{{'Route' |
                    translate}}</label>
                <div>{{'Route number' | translate}}: <strong>{{selectedCrazyDelivery.route?.routeNumber}}</strong></div>
                <div>{{'Slot' | translate}}: <strong>{{selectedCrazyDelivery.delivery?.order.slot}}</strong></div>
                <div>{{'Actual driving time' | translate}}: <strong>{{selectedCrazyDelivery.activity?.actualDrivingTime
                        | secToHoursPipe}}</strong></div>
                <div>{{'Deliveries' | translate}}: <strong>{{selectedCrazyDelivery.route?.deliveriesCount}}</strong>
                </div>
                <div>{{'Boxes' | translate}}: <strong>{{selectedCrazyDelivery.delivery?.order.boxesCount}}</strong>
                </div>
            </div>


            <ng-container *ngIf="!closeDeliveryIndexLoader">
                <div class="col">
                    <label class="tx-sans tx-10 tx-normal tx-uppercase tx-color-03 tx-spacing-1">{{'Customer' |
                        translate}}</label>
                    <div>{{closestDeliveries[this.closeDeliveryIndex].delivery?.customer?.label}} |
                        {{closestDeliveries[this.closeDeliveryIndex].delivery?.customer.status}} </div>
                    <div>{{closestDeliveries[this.closeDeliveryIndex]?.orderId}}</div>

                    <label class="tx-sans tx-10 tx-normal tx-uppercase tx-color-03 tx-spacing-1 mg-t-10">{{'Location' |
                        translate}}</label>
                    <div>{{closestDeliveries[this.closeDeliveryIndex]?.delivery?.location.rawAddress}}</div>
                    <div>{{closestDeliveries[this.closeDeliveryIndex]?.delivery?.location.coordinates.parseCoordinates}}
                    </div>
                </div>
                <div class="col">
                    <label class="tx-sans tx-10 tx-normal tx-uppercase tx-color-03 tx-spacing-1">{{'Route' |
                        translate}}</label>
                    <div>{{'Route number' | translate}}:
                        <strong>{{closestDeliveries[this.closeDeliveryIndex]?.route.routeNumber}}</strong>
                    </div>
                    <div>{{'Slot' | translate}}:
                        <strong>{{closestDeliveries[this.closeDeliveryIndex].delivery?.order.slot}}</strong>
                    </div>
                    <div>{{'Deliveries' | translate}}:
                        <strong>{{closestDeliveries[this.closeDeliveryIndex].route?.deliveriesCount}}</strong>
                    </div>
                    <div>{{'Boxes' | translate}}:
                        <strong>{{closestDeliveries[this.closeDeliveryIndex].delivery?.order.boxesCount}}</strong>
                    </div>
                    <div>{{'Distance' | translate}}:
                        <strong>{{(closestDeliveries[this.closeDeliveryIndex].activity.waypoint?.distance /
                            1000).toFixed(2)}}km</strong>
                    </div>
                    <div>{{'Duration' | translate}}:
                        <strong>{{closestDeliveries[this.closeDeliveryIndex].activity.waypoint?.duration |
                            secToHoursPipe}}</strong>
                    </div>
                </div>
            </ng-container>

            <app-loader class="col-6" [loader]="closeDeliveryIndexLoader"
                [text]="'Data loading' | translate"></app-loader>

        </div>

        <div class="row mg-t-20">
            <div class="col-6">
                <button (click)="assignDelivery(closestDeliveries[this.closeDeliveryIndex], -1)"
                    class="button btn btn-warning btn-sm btn-uppercase mg-t-20 w-100"
                    [ngClass]="{'invisible': closeDeliveryIndexLoader}">{{ 'Assign before' | translate }}</button>
            </div>
            <div class="col-6">
                <button (click)="assignDelivery(closestDeliveries[this.closeDeliveryIndex], 1)"
                    class="button btn btn-primary btn-sm btn-uppercase mg-t-20 w-100"
                    [ngClass]="{'invisible': closeDeliveryIndexLoader}">{{ 'Assign after' | translate }}</button>
            </div>
        </div>

        <div class="row mg-t-20" [style.height.vh]="50">
            <div class="col">
                <agm-map *ngIf="!closeDeliveryIndexLoader" [zoomControl]="true" [zoom]="13"
                    [latitude]="selectedCrazyDelivery.path?.points[0].lat"
                    [longitude]="selectedCrazyDelivery.path?.points[0].lng" [usePanning]="true">
                    <agm-polyline [strokeColor]="000000" strokeWeight="1" [strokeOpacity]="0.5">
                        <!-- Warehouse Frisco Marker -->
                        <agm-polyline-point [latitude]="warehouseCoords.lat"
                            [longitude]="warehouseCoords.lng"></agm-polyline-point>
                        <ng-container *ngIf="closestDeliveries[this.closeDeliveryIndex]?.routePath">
                            <agm-polyline-point
                                *ngFor="let point of closestDeliveries[this.closeDeliveryIndex]?.routePath.points"
                                [latitude]="point.lat" [longitude]="point.lng"> </agm-polyline-point>
                        </ng-container>
                        <agm-polyline-point [latitude]="warehouseCoords.lat"
                            [longitude]="warehouseCoords.lng"></agm-polyline-point>
                    </agm-polyline>

                    <ng-container *ngIf="closestDeliveries[this.closeDeliveryIndex]?.routePath">
                        <agm-marker *ngFor="let point of closestDeliveries[this.closeDeliveryIndex]?.routePath.points"
                            [iconUrl]="closestDeliveries[this.closeDeliveryIndex].activity.location.lat !== point.lat ? markerPath +'/'+ point.icon : markerPath +'/marker-crazy-delivery.png'"
                            [latitude]="point.lat" [longitude]="point.lng"
                            [label]="closestDeliveries[this.closeDeliveryIndex].activity.location.lat !== point.lat ? point.label : ''"
                            [title]="point.slot" (markerClick)="point.isOpen = !point.isOpen ">
                            <agm-info-window [isOpen]="point.isOpen"><strong>{{ 'The nearest delivery' | translate
                                    }}!</strong> <br />{{ point.description }}</agm-info-window>
                        </agm-marker>
                    </ng-container>

                    <!-- Warehouse Frisco Marker -->
                    <agm-marker [latitude]="warehouseCoords.lat" [longitude]="warehouseCoords.lng"
                        iconUrl="{{ markerPath }}/{{client}}Marker.png"></agm-marker>

                    <agm-marker [latitude]="selectedCrazyDelivery.activity.location.lat"
                        [longitude]="selectedCrazyDelivery.activity.location.lng"
                        iconUrl="{{ markerPath }}/marker-crazy-delivery2.png" [label]="''"
                        (markerClick)="selectedCrazyDelivery.path?.points[0].isOpen = !selectedCrazyDelivery.path?.points[0].isOpen">
                        <agm-info-window [isOpen]="selectedCrazyDelivery.path?.points[0].isOpen"><strong>{{ 'Crazy
                                delivery which should be move' | translate }}!</strong> <br />
                            {{selectedCrazyDelivery.path?.points[0].description}}</agm-info-window>
                    </agm-marker>
                </agm-map>
                <app-loader [loader]="closeDeliveryIndexLoader" [text]="'Data loading' | translate"></app-loader>
            </div>
        </div>
    </div>
</ng-template>


<!-- {{'ActivityType.BREAK' | translate }} -->
<!-- {{'ActivityType.DELIVERY' | translate }} -->
<!-- {{'ActivityType.DEPARTURE' | translate }} -->
<!-- {{'ActivityType.DRIVING' | translate }} -->
<!-- {{'ActivityType.LOAD' | translate }} -->
<!-- {{'ActivityType.RELOAD' | translate }} -->
<!-- {{'ActivityType.RETURN' | translate }} -->
<!-- {{'ActivityType.UNLOAD' | translate }} -->