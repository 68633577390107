import * as _ from 'lodash';
import * as moment from 'moment';
import { Moment } from 'moment';

import { Entity } from '@entities/Entity';
import { Serializable } from '@entities/Serializable';

import { DailyShiftConfig } from './dailyShiftConfig';
import { DayComposite } from './dayComposite';

export class MonthlyShiftConfig extends Entity implements Serializable<MonthlyShiftConfig> {
    private readonly tag = '[MonthlyShiftConfig]';

    public path: string;
    public date: string;
    public status: string;
    public days: DayComposite[] = [];
    public mMonth: Moment = null;
    public gapSize = 0;
    public gaps: any[] = [];

    constructor() {
        super();
    }

    public getDay(date) {
        const result = _.find(this.days, (dayComposite: DayComposite) => {
            return dayComposite.dateRaw === date;
        });

        if (!_.isUndefined(result)) {
            return result;
        } else {
            return new DayComposite(date);
        }

        //throw new Error('Not Found');
    }

    public deserialize(data: any) {
        console.log(this.tag, '[deserialize]', data);

        this.date = _.get(data[0], 'date');
        this.mMonth = moment(_.get(data[0], 'date'), 'YYYY-MM');

        this.status = _.get(data, 'status', 'closed');

        const daysInMonth = this.mMonth.daysInMonth();
        const firstDayOfMonth: Moment = this.mMonth.clone().startOf('date');

        this.gapSize = firstDayOfMonth.isoWeekday() - 1;

        for (let i = 1; i <= this.gapSize; i++) {
            this.gaps.push({
                weekday: i
            });
        }
        const daysData = data;

        const days = {};

        _.forEach(daysData, (dayData: any) => {
            const day = (new DailyShiftConfig()).deserialize(dayData);
            days[day.mDay.format('YYYY-MM-DD')] = day;
        });

        const dayIteratorArg = firstDayOfMonth.clone();

        for (let i = 1; i <= daysInMonth; i++) {
            const dateValue = dayIteratorArg.format('YYYY-MM-DD');

            const dayComposite = new DayComposite(dateValue);

            dayComposite.dayOfMonth = i;
            dayComposite.weekday = dayIteratorArg.isoWeekday();

            if (!_.isUndefined(days[dateValue])) {
                dayComposite.dailyShiftConfig = days[dateValue];
            }

            this.days.push(dayComposite);

            dayIteratorArg.add(1, 'd');
        }

        return this;
    }

    public serialize() {
        return {};
    }
}
