
        <layout-with-sidebar>
            <div header>
                <div toolbar>
                    <h4><i-feather name="calendar"></i-feather> {{ 'Calendar' | translate }}</h4>
                </div>
            </div>

            <div content class="calendar row no-gutters">
                <div class="col-12">
                    <div class="calendar__header">
                        <div class="calendar__nav previous" (click)="showPreviousMonth()"><i class="fas fa-angle-left"></i> {{ 'Previous' | translate }}</div>
                        <h5 class="calendar__nav">
                            {{ monthLabel | amLocale: locale | amDateFormat: 'MMMM YYYY' }} <i-feather (click)="reload()" name="refresh-cw" class="calendar__refresh" [ngClass]="{'calendar__refresh--rotate': rotate}"></i-feather>
                        </h5>
                        <div class="calendar__nav next" (click)="showNextMonth()">
                            {{ 'Next' | translate }}
                            <i class="fas fa-angle-right"></i>
                        </div>
                    </div>
                    <div class="calendar__weekdays">
                        <div class="weekdayLabel {{ weekday }} day{{ i + 1 }}" *ngFor="let weekday of weekLabels; let i = index">{{ weekday }}</div>
                    </div>
                    <ng-container *ngIf="(currentMonth$ | async) as currentMonth; else loaderRef;">
                        <ng-container *ngIf="!loader; else loaderRef">
                        
                            <div class="calendar__daysContainer">
                                <div class="day gap day{{ gap.weekday }}" *ngFor="let gap of currentMonth.gaps"
                                    [style.height.px]="dayDetailsHeight * 40 + 28"></div>
                                <div
                                    *ngFor="let dayComposite of currentMonth.days"
                                    class="day day{{ dayComposite.weekday }}"
                                    [style.height.px]="dayDetailsHeight * 40 + 28"
                                    [class.today]="dayComposite.isToday"
                                    [class.future-day]="dayComposite.isFutureDay"
                                    [class.tomorrow-day]="dayComposite.isTomorrow"
                                    [class.past-day]="dayComposite.isPastDay"
                                    [class.active]="dayComposite.dayOfMonth == selectedDay?.dayOfMonth">
                                    <day [dayComposite]="dayComposite" (click)="showDetailsForDay(dayComposite)"></day>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-template #loaderRef>
                        <div class="load-spinner spinner">
                            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                            <p class="spinner__info">{{ 'Loading, shifts for' | translate }}: <strong>{{ monthLabel | amLocale: locale | amDateFormat: 'MMMM YYYY' }}</strong></p>
                        </div>
                    </ng-template>
                </div>
            </div>

            <div sidebar>
                <sidebar-with-header>
                    <div *ngIf="selectedDay !== null && selectedDay.dailyShiftConfig else noShifts" header>
                        <div  class="shift-menu">
                            <ng-container *ngFor="let shift of selectedDay.dailyShiftConfig.shifts | sortShiftByStartTimePipe">
                                <div [ngClass]="{ active: shift.fulfilment.shiftId.type === selectedShift, shift: true }" (click)="setShift(shift.fulfilment.shiftId.type)">
                                    <i-feather *ngIf="shift.shiftStatusData.status | isShiftOpenPipe" name="unlock" [style.height.px]="15" [style.width.px]="15" [style.color]="'green'"></i-feather>
                                    <i-feather *ngIf="!(shift.shiftStatusData.status | isShiftOpenPipe)" name="lock" [style.height.px]="15" [style.width.px]="15" [style.color]="'red'"></i-feather>
                                    {{ shift.fulfilment.shiftId.type | translate }}
                                    <span *ngIf="selectedDay" [class.green]="shift.shiftStatusData.status | isShiftOpenPipe" [class.red]="!shift.shiftStatusData.status | isShiftOpenPipe"></span>
                                </div>
                            </ng-container>
                            <div appCheckPrivileges [permissions]="[Permissions.Shift_planning_edit]" *ngIf="selectedDay && !selectedDay.isPastDay" class="shift" (click)="addingShift()">
                                <i-feather name="plus" [style.height.px]="15" [style.width.px]="15" [style.color]="'green'"></i-feather> {{'Add' | translate}}
                            </div>
                        </div>
                    </div>

                    <ng-template #noShifts>
                        <div *ngIf="selectedDay && !selectedDay.isPastDay" class="shift-menu" header>
                            <div class="shift" (click)="addingShift()">
                                <i-feather name="plus" [style.height.px]="15" [style.width.px]="15" [style.color]="'green'"></i-feather> {{'Add shift' | translate}}
                            </div>
                        </div>
                    </ng-template>

                    <div content>
                        <day-details *ngIf="selectedDay" [dayComposite]="selectedDay" [selectedShift]="selectedShift" (dayCompositeChanged)="onDayCompositeChanged($event)"></day-details>
                    </div>
                </sidebar-with-header>
            </div>
        </layout-with-sidebar>

        <ng-template #addingShiftDialog>
            <div class="modal-header">
                <h4 class="modal-title">{{'Adding shift' | translate }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
            </div>

            <div class="modal-body text-center">
                <app-shift-create [date]="(currentMonth.days.length) ? selectedDay : null" (updatedData)="shiftPlanningForm($event)"></app-shift-create>

                <button [routerLink]="['/calendar/shift-planning']" type="button" class="btn btn-default" (click)="decline()">{{'Shift planning list' | translate }}</button>

                <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
                <button type="button" class="btn btn-brand-01" (click)="confirm()">
                    {{'Create shift' | translate }}
                </button>
            </div>
        </ng-template>

        <!-- {{'SHIFT_STATUS_CONFIGURING' | translate}} -->
        <!-- {{'SHIFT_STATUS_BOOKING' | translate}} -->
        <!-- {{'SHIFT_STATUS_PLANNING' | translate}} -->
        <!-- {{'SHIFT_STATUS_FULLFILLMENT' | translate}} -->
        <!-- {{'SHIFT_STATUS_DELIVERING' | translate}} -->
        <!-- {{'SHIFT_STATUS_COMPLETED' | translate}} -->
    