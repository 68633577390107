import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { Subscription, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Roles, AppLang } from '@enums/enum';
import { NavService } from '@services/nav.sevice';

import { UserPanel } from '../entities/user-panel';
import { UsersService } from '../services/users.service';
import { DynamicRoles } from '../entities/roles';

@Component({
    selector: 'app-edit-user',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit, OnDestroy {
    private readonly TAG = '[EditUserComponent';
    private userQueryParams: Subscription;
    private userId: string;

    public mode: string = 'list';
    public form: FormGroup;
    public submitted: boolean = false;
    public user: UserPanel;

    public roles: DynamicRoles[];
    public dynamicRolesCtrl: FormArray;

    public formLoader: boolean = true;

    constructor(
        private navigationRoute: ActivatedRoute,
        private usersService: UsersService,
        private formBuilder: FormBuilder,
        private notifierService: NotifierService,
        private translateService: TranslateService,
        private navService: NavService
    ) {}

    get rolesOptions() {
        return _.keys(Roles);
    }

    set selectedUserId(userId) {
        if (_.isUndefined(userId) || userId === '') {
            return;
        }

        this.usersService.getUser(userId).subscribe((user: UserPanel) => {
            console.log(`${this.TAG} Edit mode for user`, user);
            this.user = user;
            this.initForm();
        });
    }

    public ngOnInit() {

        this.roles = this.navigationRoute.snapshot.data.roles;
        this.userQueryParams = this.navigationRoute.params.subscribe(params => this.userId = _.get(params, 'userId', ''));

        this.navigationRoute.params.subscribe(params => {
            if (params.action === 'create') {
                this.mode = 'create';
                this.initForm();
                this.selectedUserId = '';
            } else if (params.action === 'show') {
                this.mode = 'show';
                this.selectedUserId = params.userId;
            } else if (params.action === 'edit') {
                this.mode = params.action;
                this.selectedUserId = params.userId;
            }
        });

    }

    private initForm() {
        this.form = this.formBuilder.group({
            id: [this.user ? this.user.id : null],
            username: [this.user ? this.user.username : null, [Validators.required, Validators.email, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$') ]],
            firstName: [this.user ? this.user.firstName : null, Validators.required],
            lastName: [this.user ? this.user.lastName : null, Validators.required],
            passwordHash: [this.user ? this.user.passwordHash : null, [Validators.minLength(8)]],
            roles: [this.user ? this.user.roles : [Roles.ROLE_DISPATCHER]],
            dynamicRoles: this.formBuilder.array([]),
            modifiedAt: [this.user ? this.user.modifiedAt : null],
            createdAt: [this.user ? this.user.createdAt : null],
            version: [this.user ? this.user.version : null]
        });

        this.dynamicRolesCtrl = <FormArray>this.form.controls['dynamicRoles'];
        if (this.roles) {
          this.roles.forEach((item) => this.dynamicRolesCtrl.push(this.patchValues(item)));
        }

        this.formLoader = false;
    }

    private patchValues(role: DynamicRoles): AbstractControl {

        const enabled = (this.user) 
                ? (this.user.dynamicRoles.find((i: DynamicRoles) => i.name === role.name) !== undefined) ? true : false
                : false;

        return this.formBuilder.group({
          role: role,
          enabled: [enabled],
        })
    }

    public ngOnDestroy() {
        if (this.userQueryParams) {
            this.userQueryParams.unsubscribe();
        }
    }

    public onSubmit(): void {
        this.submitted = true;
        const user: UserPanel = plainToClass(UserPanel, this.form.getRawValue());
        const dr = [];

        _.forEach(this.form.get('dynamicRoles').value, (item: {enabled: boolean, role: DynamicRoles}) => {
            if (item.enabled){
                dr.push(item.role)
            }
        })
        
        user.dynamicRoles = dr;

        if (this.mode === 'create') {
            delete user.version;
            delete user.id;
            this.usersService
                .create(user)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        this.submitted = false;
                        return observableThrowError(error);
                    })
                )
                .subscribe(
                    response => {
                        console.log(this.TAG, 'Creating user', response);
                        this.notifierService.notify('success', this.translateService.instant('User has been created'));
                        this.navService.goToPage('/users', { modifiedId: user.id });
                        this.submitted = false;
                    },
                    error => {
                        console.log(`${this.TAG} Creating driver - error`, error);

                        this.submitted = false;
                    },
                    () => {
                        this.submitted = false;
                    }
                );
        } else if (this.mode === 'edit') {
            this.usersService
                .update(user)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        this.submitted = false;
                        return observableThrowError(error);
                    })
                )
                .subscribe(
                    response => {
                        console.log(this.TAG, 'Updating user', response);
                        this.notifierService.notify('success', this.translateService.instant('User has been updated'));
                        this.navService.goToPage('/users', { modifiedId: response.id });
                        this.submitted = false;
                    },
                    error => {
                        console.log(`${this.TAG} Updating driver - error`, error);
                        this.submitted = false;
                    },
                    () => {
                        this.submitted = false;
                    }
                );
        }
    }
}
