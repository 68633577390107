import { Roles } from '@enums/enum';
import { DynamicRoles } from './roles';

export class UserPanel {
    public id: string;
    public username: string;
    public passwordHash: string;
    public firstName: string;
    public lastName: string;
    public createdAt: string;
    public modifiedAt: string;
    public version: string;
    public roles: Roles[];
    public dynamicRoles: DynamicRoles[];
}
