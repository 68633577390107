import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeliveryStatus, ShiftType } from '@enums/enum';
import { ShiftConfig } from './entities/shiftConfig';
import { ShiftExt } from './interafces/shift-ext.interface';

@Component({
    selector: 'day',
    template: `
        <span class="numberLabel">{{ dayComposite.dayOfMonth }}</span>
        <div class="shift-overview">
            <table class="shift-overview__table">
                <tbody class="shift-overview__shift" *ngFor="let shift of dayComposite.dailyShiftConfig?.shifts | sortShiftByStartTimePipe" [ngClass]="shift.fulfilment.type">
                    <tr>
                        <td [style.width.%]="80" class="shift-type" [tooltip]="shift.fulfilment.shiftId.type | translate" [containerClass]="'pretty-tooltip'">{{ shift.fulfilment.shiftId.type.slice(0, 11) | translate }} ({{ shift.statuses['RESERVED'] | number }}/{{ shift.statuses['ORDERED'] | number }})</td>

                        <td class="shift-overview__fleet" [style.width.%]="20" [style.text-align]="'right'" colspan="1">
                            {{ shift.fulfilment.fleetMaximum }}
                        </td>
                    </tr>

                    <tr>
                        <td class="bars">
                            <div>
                                <div *ngIf="!(shift.shiftStatusData.status | isShiftOpenPipe)" class="bars__shift-status"><i class="fas fa-lock"></i></div>
                            </div>

                            <div class="bars__bar" [ngClass]="{'limit-alert': shift | checkShiftLimitsPipe }" [tooltip]="tooltipLabel(shift)" [adaptivePosition]="true" [containerClass]="'pretty-tooltip'" [style.width.%]="!(shift.shiftStatusData.status | isShiftOpenPipe) ? 82 : 95">
                                <div
                                    [style.flex]="shift.statuses['RESERVED']"
                                    class="bars__deliveries bars__deliveries--reserved"
                                    [style.background-color]="isLimitExceeded(shift) ? '#FF4136' : isEstimatedMaxNoDeliveriesExceeded(shift) && '#FFDC00'"
                                ></div>
                                <div
                                    [style.flex]="shift.statuses['ORDERED']"
                                    class="bars__deliveries bars__deliveries--ordered"
                                    [style.background-color]="isLimitExceeded(shift) ? '#FF4136' : isEstimatedMaxNoDeliveriesExceeded(shift) && '#FFDC00'"
                                ></div>
                                <div [style.flex]="shift.statuses['DONE']" class="bars__deliveries bars__deliveries--done"></div>
                                <div [style.flex]="shift.statuses['FAILURE']" class="bars__deliveries bars__deliveries--failure"></div>
                                <div [style.flex]="shift.statuses['ASSIGNED']" class="bars__deliveries bars__deliveries--assigned"></div>
                                <div [style.flex]="shift.statuses['RETRY']" class="bars__deliveries bars__deliveries--retry"></div>
                                <div [style.flex]="shift.statuses['PENDING']" class="bars__deliveries bars__deliveries--pending"></div>
                                <div [style.flex]="shift.statuses['ARCHIVED']" class="bars__deliveries bars__deliveries--archived"></div>
                                <div [style.flex]="shift.statuses['NEW']" class="bars__deliveries bars__deliveries--new"></div>
                                <div [style.flex]="shift.statuses['CUTOFF']" class="bars__deliveries bars__deliveries--cutoff"></div>
                                <div [style.flex]="shift.statuses['FINALIZING']" class="bars__deliveries bars__deliveries--finalizing"></div>
                                <div *ngIf="shift.shiftStatusData.status | isShiftOpenPipe" [style.flex]="flexGrowEstimatedMaxNoDeliveries(shift)" class="bars__deliveries bars__deliveries--maximum-deliveries"></div>
                            </div>
                        </td>
                        <td [style.text-align]="'right'">
                            <span *ngIf="!fullShift(shift)" class="shift-overview__maximum-deliveries" [tooltip]="allDeliveriesTooltip(shift)" [containerClass]="'pretty-tooltip'">
                                {{ estimatedMaxNoDeliveries(shift.fulfilment.fleetMaximum, shift.fulfilment.shiftId.type) }}
                            </span>

                            <span *ngIf="fullShift(shift)" [tooltip]="limitLabel()" class="shift-overview__maximum-deliveries" [containerClass]="'pretty-tooltip'" [style.color]="'red'">
                                {{ estimatedMaxNoDeliveries(shift.fulfilment.fleetMaximum, shift.fulfilment.shiftId.type) }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    `,
    styleUrls: ['day.component.scss']
})
export class DayComponent implements OnInit {
    private readonly numberDeliveriesPerVanMorning: number = 19.3;
    private readonly numberDeliveriesPerVanAfternoon: number = 20;
    private readonly numberDeliveriesPerVanEvening: number = 14;

    @Input() public dayComposite: any;

    constructor(private translate: TranslateService) {
        
    }

    ngOnInit() {
       
    }

    public flexGrowEstimatedMaxNoDeliveries(shift: ShiftExt): number {
        return (
            this.estimatedMaxNoDeliveries(shift.fulfilment.fleetMaximum, shift.fulfilment.shiftId.type) -
            shift.statuses[DeliveryStatus.ORDERED] -
            shift.statuses[DeliveryStatus.RESERVED] -
            shift.statuses[DeliveryStatus.ASSIGNED] -
            shift.statuses[DeliveryStatus.DONE] -
            shift.statuses[DeliveryStatus.FAILURE] -
            shift.statuses[DeliveryStatus.RETRY] -
            shift.statuses[DeliveryStatus.PENDING] -
            shift.statuses[DeliveryStatus.CUTOFF] -
            shift.statuses[DeliveryStatus.FINALIZING] -
            shift.statuses[DeliveryStatus.ARCHIVED]
        );
    }

    public countAllDeliveriesForDay(shift: ShiftConfig): number {
        return (
            shift.statuses[DeliveryStatus.ORDERED] +
            shift.statuses[DeliveryStatus.RESERVED] +
            shift.statuses[DeliveryStatus.ASSIGNED] +
            shift.statuses[DeliveryStatus.DONE] +
            shift.statuses[DeliveryStatus.FAILURE] +
            shift.statuses[DeliveryStatus.RETRY] +
            shift.statuses[DeliveryStatus.PENDING] +
            shift.statuses[DeliveryStatus.CUTOFF] +
            shift.statuses[DeliveryStatus.FINALIZING] +
            shift.statuses[DeliveryStatus.NEW] +
            shift.statuses[DeliveryStatus.ARCHIVED]
        );
    }

    private estimatedMaxNoDeliveries(maxVanNumber: number, shiftType: ShiftType): number {
        if (shiftType === ShiftType.MORNING_1) {
            return Math.ceil(maxVanNumber * this.numberDeliveriesPerVanMorning);
        } else if (shiftType === ShiftType.AFTERNOON) {
            return Math.ceil(maxVanNumber * this.numberDeliveriesPerVanAfternoon);
        } else if (shiftType === ShiftType.EVENING) {
            return Math.ceil(maxVanNumber * this.numberDeliveriesPerVanEvening);
        } else {
            return Math.ceil(maxVanNumber * this.numberDeliveriesPerVanMorning);
        }
    }

    public fullShift(shift: ShiftConfig): boolean {
        return (shift.maximumDeliveries !== -1) 
            ? this.estimatedMaxNoDeliveries(shift.fleetMaximum, shift.type) > shift.maximumDeliveries : false
    }

    private isEstimatedMaxNoDeliveriesExceeded(shift: ShiftConfig): boolean {
        return shift.statuses['RESERVED'] + shift.statuses['ORDERED'] > this.estimatedMaxNoDeliveries(shift.fleetMaximum, shift.type);
    }

    private isLimitExceeded(shift: ShiftConfig): boolean {
        return (shift.maximumDeliveries !== -1) ? shift.statuses['RESERVED'] + shift.statuses['ORDERED'] >= shift.maximumDeliveries : false;
    }

    public allDeliveriesDone(shift: ShiftConfig): string {
        return shift.statuses[DeliveryStatus.ASSIGNED] === 0 ? '#2ECC40' : 'auto';
    }

    public checkLimits(shift){
        console.log(shift);
        return true;
    }

    public tooltipLabel(shift: ShiftExt): string {
        const N = '\n';

        const RESERVED: string = `${shift.statuses['RESERVED'] ? this.translate.instant('Reserved deliveries') + ': ' + shift.statuses['RESERVED'] + N : ''}`;
        const ORDERED: string = `${shift.statuses['ORDERED'] ? this.translate.instant('Ordered deliveries') + ': ' + shift.statuses['ORDERED'] + N : ''}`;
        const ASSIGNED: string = `${shift.statuses['ASSIGNED'] ? this.translate.instant('Assigned deliveries') + ': ' + shift.statuses['ASSIGNED'] + N : ''}`;
        const DONE: string = `${shift.statuses['DONE'] ? this.translate.instant('Done deliveries') + ': ' + shift.statuses['DONE'] + N : ''}`;
        const FAILURE: string = `${shift.statuses['FAILURE'] ? this.translate.instant('Failure deliveries') + ': ' + shift.statuses['FAILURE'] + N : ''}`;
        const RETRY: string = `${shift.statuses['RETRY'] ? this.translate.instant('Retry deliveries') + ': ' + shift.statuses['RETRY'] + N : ''}`;
        const PENDING: string = `${shift.statuses['PENDING'] ? this.translate.instant('Pending deliveries') + ': ' + shift.statuses['PENDING'] + N : ''}`;
        const ARCHIVED: string = `${shift.statuses['ARCHIVED'] ? this.translate.instant('Archived deliveries') + ': ' + shift.statuses['ARCHIVED'] + N : ''}`;
        const CUTOFF: string = `${shift.statuses['CUTOFF'] ? this.translate.instant('Cutoff deliveries') + ': ' + shift.statuses['CUTOFF'] + N : ''}`;
        const NEW: string = `${shift.statuses['NEW'] ? this.translate.instant('New deliveries') + ': ' + shift.statuses['NEW'] + N : ''}`;
        const FINALIZING: string = `${shift.statuses['FINALIZING'] ? this.translate.instant('Finalizing deliveries') + ': ' + shift.statuses['FINALIZING'] + N : ''}`;


        return `${this.translate.instant(shift.fulfilment.shiftId.type)}${N}${RESERVED}${ORDERED}${ASSIGNED}${DONE}${FAILURE}${RETRY}${PENDING}${ARCHIVED}${NEW}${CUTOFF}${FINALIZING}${this.translate.instant(
            'Maximum Deliveries'
        )}: ${(shift.fulfilment.maximumDeliveries !== -1) ? shift.fulfilment.maximumDeliveries : this.translate.instant('Disabled')}
${this.translate.instant('Fleet size')}: ${shift.fulfilment.fleetMaximum}`;
return '';
    }

    public limitLabel(): string {
        return this.translate.instant('Shift limit too low!');
    }

    public allDeliveriesTooltip(shift: ShiftConfig): string {
        return `${this.translate.instant('Actual deliveries')}: ${this.countAllDeliveriesForDay(shift)}`;
    }
}
