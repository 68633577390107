import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AgmCoreModule } from '@agm/core';
import { NgDatepickerModule } from 'ng2-datepicker';
import { TooltipModule } from 'ngx-bootstrap';

import { CalendarModule } from '@calendar/calendar.module';

import { SharedModule } from '../shared.module';
import { BookingAvailabilityContentComponent } from './components/booking-availability-content.component';
import { BookingAvailabilityComponent } from './components/booking-availability.compnent';
import { AurelsShiftPickerComponent } from './components/datepicker/aurels-shift-picker.component';
import { GridComponent } from './components/grid/grid.component';
import { MapComponent } from './components/map/map.component';
import { OrdersHistoryMapComponent } from './components/orders-history-map.component';
import { RouteMapComponent } from './components/route-map.component';
import { VisualiserService } from './services/visualiser.service';
import { VisualiserComponent } from './visualiser.component';
import { VisualiserRouting } from './visualiser.routing';
import { StartShiftPipe } from './pipes/start-shift.pipe';
import { EndShiftPipe } from './pipes/end-shift.pipe';
import { DeliveryTooltipPipe } from './pipes/delivery-tooltip.pipe';
import { TotalDistancePipe } from './pipes/total-distance.pipe';
import { DistancePerDeliveryPipe } from './pipes/distance-per-delivery.pipe';
import { DrivingTimePerDeliveryPipe } from './pipes/driving-time-delivery.pipe';
import { DetailWidthPipe } from './pipes/detail-width.pipe';
import { DeliveryWidthPipe } from './pipes/delivery-width.pipe';
import { DistanceLabelPipe } from './pipes/distance-label.pipe';
import { DeliveriesPerVansPipe } from './pipes/deliveries-per-van.pipe';
import { PlannedDepartureTimePipe } from './pipes/planned-departure-time.pipe';
import { EndShiftPaddingPipe } from './pipes/end-shift-padding.pipe';
import { StartShiftLabelPipe, EndShiftLabelPipe } from './pipes/end-start-shift-label.pipe';
import { CountRealRoutesPipe } from './pipes/count-real-routes.pipe';
import { PlanningSequenceComponent } from './components/planning-sequence/planning-sequence/planning-sequence.component';
import { EditPlanningSequenceComponent } from './components/planning-sequence/edit-planning-sequence/edit-planning-sequence.component';
import { PlanningSequenceService } from './services/planning-sequence.service';
import { DividePerVansPipe } from './pipes/divide-per-vans.pipe';
import { FindByStatusShiftPipe } from './pipes/find-by-status.pipe';
import { DrivingTimePerRoutesPipe } from './pipes/driving-time-per-routes.pipe';
import { DistancePerRoutesPipe } from './pipes/distance-per-routes.pipe';
import { RouteResolver } from '@routes/route.resolver';
import { DeliveriesService } from '@deliveries/services/deliveries.services';
import { RoutesService } from '@routes/services/routes.service';
import { IsCrazyDeliveryPipe } from './pipes/is-crazy-delivery.pipe';
import { FindWaitingTimePipe } from './pipes/find-waiting-time.pipe';
import { VisualiserRoutesPlanningComponent } from './components/visualiser-routes-planning/visualiser-routes-planning.component';
import { VisualiserRoutesPlanningHeaderComponent } from './components/visualiser-routes-planning-header/visualiser-routes-planning-header.component';
import { VisualiserRoutesPlanningBarsComponent } from './components/visualiser-routes-planning-bars/visualiser-routes-planning-bars.component';
import { ShiftWorkingHoursPipe } from './pipes/shift-working-hours.pipe';
import { VisualiserCutoffComponent } from './components/visualiser-cutoff/visualiser-cutoff.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { VisualiserSolutionDetailsComponent } from './components/visualiser-solution-details/visualiser-solution-details.component';
import { VisualiserCrazyDeliveriesComponent } from './components/visualiser-crazy-deliveries/visualiser-crazy-deliveries.component';
import { VisualiserSimulatorComponent } from './components/visualiser-simulator/visualiser-simulator.component';
import { FindLocationComponent } from './components/find-location/find-location.component';
import { MaxNoBoxesExceededPipe } from './pipes/max-no-boxes-exceeded.pipe';
import { VisualiserSimulatorShiftManagementComponent } from './components/visualiser-simulator-shift-management/visualiser-simulator-shift-management.component';
import { VisualiserSimulatorSolutionViewComponent } from './components/visualiser-simulator-solution-view/visualiser-simulator-solution-view.component';
import { CutoffShiftStatusCheckerPipe } from './pipes/cutoff-shift-status-checker.pipe';
import { FindBestSolutionPipe } from './pipes/find-best-solution.pipe';
import { ActivityLateEarlyMarkPipe } from './pipes/activity-early-late-mark.pipe';
import { CountBySolutionStatusPipe } from './pipes/countby-solution-status.pipe';
import { FindFinderEventPipe } from './pipes/find-finderevent.pipe';
import { environment } from '@environment';
import { FindValidatorPipe } from './pipes/find-validator.pipe';
import { FinalizeDialogComponent } from './components/dialogs/finalize-dialog/finalize-dialog.component';

// todo: APIKEY should be in config

@NgModule({
    declarations: [
        VisualiserComponent,
        MapComponent,
        GridComponent,
        AurelsShiftPickerComponent,
        RouteMapComponent,
        OrdersHistoryMapComponent,
        BookingAvailabilityComponent,
        BookingAvailabilityContentComponent,
        StartShiftPipe,
        EndShiftPipe,
        DeliveryTooltipPipe,
        TotalDistancePipe,
        DistancePerDeliveryPipe,
        DrivingTimePerDeliveryPipe,
        DetailWidthPipe,
        DeliveryWidthPipe,
        DistanceLabelPipe,
        DeliveriesPerVansPipe,
        PlannedDepartureTimePipe,
        EndShiftPaddingPipe,
        StartShiftLabelPipe,
        EndShiftLabelPipe,
        CountRealRoutesPipe,
        PlanningSequenceComponent,
        EditPlanningSequenceComponent,
        DividePerVansPipe,
        FindByStatusShiftPipe,
        DrivingTimePerRoutesPipe,
        DistancePerRoutesPipe,
        IsCrazyDeliveryPipe,
        FindWaitingTimePipe,
        VisualiserRoutesPlanningComponent,
        VisualiserRoutesPlanningHeaderComponent,
        VisualiserRoutesPlanningBarsComponent,
        ShiftWorkingHoursPipe,
        VisualiserCutoffComponent,
        VisualiserSolutionDetailsComponent,
        VisualiserCrazyDeliveriesComponent,
        VisualiserSimulatorComponent,
        FindLocationComponent,
        MaxNoBoxesExceededPipe,
        VisualiserSimulatorShiftManagementComponent,
        VisualiserSimulatorSolutionViewComponent,
        CutoffShiftStatusCheckerPipe,
        FindBestSolutionPipe,
        ActivityLateEarlyMarkPipe,
        CountBySolutionStatusPipe,
        FindFinderEventPipe,
        FindValidatorPipe,
        FinalizeDialogComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        NgDatepickerModule,
        AgmCoreModule.forRoot({
            apiKey: environment.googleMapApiKey,
            libraries: ['visualization', 'places']
        }),
        VisualiserRouting,
        TooltipModule.forRoot(),
        CalendarModule,
        NgxDatatableModule,

    ],
    providers: [
        VisualiserService,
        PlanningSequenceService,
        RouteResolver,
        DeliveriesService,
        RoutesService
    ],
    exports: [
        AurelsShiftPickerComponent,
        RouteMapComponent,
        GridComponent,
        BookingAvailabilityContentComponent
    ],
    bootstrap: [],
    entryComponents: [
        FinalizeDialogComponent
    ]
})
export class VisualiserModule {}
