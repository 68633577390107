import { Observable, Subject, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { CrudOperations } from '@interfaces/crud.interfaces';
import { PageableResponse } from '@entities/pagable-response';
import { UtilsService } from './utils.service';
import { ORDER } from '@enums/enum';
import { QueryParams } from '@interfaces/query-params.interface';
import { environment } from '@environment';
import { UrlUtil } from '@shared/functions/UrlUtil';

export abstract class CrudService<T, ID> implements CrudOperations<T, string> {

  private queryParamsSubject: Subject<QueryParams> = new ReplaySubject<QueryParams>(1);
  
  list$: Observable<PageableResponse<T>> = this.queryParamsSubject.asObservable()
    .pipe(
      switchMap(queryParams => this.findAllPageableResponse(queryParams))
    );

  private readonly paginationEndpoint = '/list';

  constructor(
    protected _http: HttpClient,
    protected _base: string,
    protected utilsService: UtilsService
  ) {
    console.log(this._base);
    //this._base = this.utilsService.interpolate(this._base, {params})
  }

  public save(t: T, params: Object = {}, dynamicEndpoint = null): Observable<T> {
    const endpoint: string = this.utilsService.interpolate((!dynamicEndpoint) ? this._base : dynamicEndpoint, params);
    return this._http.post<T>(endpoint + "/" , t);
  }

  public update(id: string, t: T, params: Object = {}): Observable<T> {
    const endpoint: string = this.utilsService.interpolate(this._base, params);
    return this._http.put<T>(endpoint + "/" + id, t, {});
  }

  public findOne(id: string, params: Object = {}): Observable<T> {
    const endpoint: string = this.utilsService.interpolate(this._base, params);
    return this._http.get<T>(endpoint + "/" + id);
  }

  public findAll(params: Object = {}): Observable<T[]> {
    const endpoint: string = this.utilsService.interpolate(this._base, params);
    return this._http.get<T[]>(endpoint + "/list")
  }

  public fetchAll(params?: any) {
    params = (params) ? params : {size: 1000, order: 'ASC', page: 0, sort: 'createdAt'};
    this.queryParamsSubject.next((params));
  }

  public findAllPageableResponse(params: Object = environment.pagination): Observable<PageableResponse<T>> {
    let endpoint: string = this.utilsService.interpolate(this._base + this.paginationEndpoint, params);
    

    const paramsString = UrlUtil.objectToString(params);

    if (paramsString) {
      endpoint += '?' + paramsString;
    }

    return this._http.get<PageableResponse<T>>(endpoint)
  }

  public delete(id: ID | string, params: Object = {}): Observable<T> {
    const endpoint: string = this.utilsService.interpolate(this._base, params);
    return this._http.delete<T>(endpoint + '/' + id);
  }
}
