<layout-default>
        <div header>
            <div toolbar>
                <h4 *ngIf="mode === 'CREATE' else update">
                    <i-feather name="package"></i-feather> {{'Create depot' | translate}}
                </h4>
                <ng-template #update>
                    <h4 *ngIf="mode === 'UPDATE'">
                        <i-feather name="package"></i-feather> {{'Edit depot' | translate}}
                    </h4>
                </ng-template>
            </div>
    
            <hr />
        </div>
        
        <div content>
    
            <ng-container *ngIf="(depot$ | async) as depot"></ng-container>
    
                <div *ngIf="!loader" class="content bg-white">
    
                    <div class="card-content col-8 offset-2">
    
                        <form [formGroup]="form">
    
                            <div id="input-code" class="form-group row">

                                <label for="depot" class="col-2">
                                    <span>{{'Code' | translate}}:&nbsp;</span>
                                    <span *ngIf="form.get('code') | isRequiredControlPipe">*</span>&nbsp;
                                    <i-feather *ngIf="tooltipLabel('code')" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('code')"
                                        [style.width.px]="13" name="info"></i-feather>
                                </label>

                                <div class="col-10">
                                    <input id="code" formControlName="code" class="form-control" />
                                    <div *ngIf="form.get('code').hasError('maxlength') && form.get('code').touched" class="error-color">
                                        {{ "The field value is too long" | translate }}!
                                    </div>
                                </div>
                            </div>

                            <div id="input-name" class="form-group row">
                                <label class="col-2" for="code">{{'Name' | translate}}:</label>
                                <div class="col-10">
                                    <input id="name" formControlName="name" class="form-control" />
                                    <div *ngIf="form.get('name').hasError('maxlength') && form.get('name').touched" class="error-color">
                                        {{ "The field value is too long" | translate }}!
                                    </div>
                                </div>
                            </div>
    
                            <ng-container formGroupName="address">
                                <label class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{'Address' | translate}}</label>
    
                                <div id="input-{{item}}" *ngFor="let item of addressKeys" class="form-group row">
                                    <label class="col-2" for="code">{{item.toUpperCase() | translate}}:</label>
                                    <div class="col-10">
                                        
                                        <input *ngIf="item === 'zip'" [id]="item" [formControlName]="item" [dropSpecialCharacters]="false" mask="00-000" class="form-control" />
                                        <input *ngIf="item !== 'zip'" [id]="item" [formControlName]="item" class="form-control" />
    
                                        <div *ngIf="form.get('address').get(item)?.hasError('required') && form.get('address').get(item)?.touched" class="error-color">
                                            {{ "The field is required" | translate }}!
                                        </div>
                                        <div *ngIf="item === 'zip' && form.get('address').get('zip').hasError('pattern') && form.get('address').get('zip').touched" class="error-color">
                                            {{ "The zip code you entered is invalid" | translate }}!
                                        </div>
                                        <div *ngIf="form.get('address').get(item)?.hasError('maxlength') && form.get('address').get(item)?.touched" class="error-color">
                                            {{ "The field value is too long" | translate }}!
                                        </div>
                                    </div>
                                </div>
    
                            </ng-container>

                            <div id="input-allowed-zip" class="form-group row zip-codes">

                                <label class="col-2" for="code">{{'allowedZipCodes' | translate}}:</label>
                              

                                <div class="col-10">
                                    <ng-container formArrayName="allowedZipCodes">
                                        <div *ngFor="let control of allowedZipCodesControl.controls; let index=index" class="form-horizontal">
                                            <input [formControlName]="index" [dropSpecialCharacters]="false" mask="99-999" class="form-control" />
                                            <button (click)="removeAllowedZipCodes(index)" class="btn btn-xs d-inline btn-white remove pd-t-2 pd-b-2"><i-feather [style.height.px]="16" name="trash-2"></i-feather></button>
                                            <button *ngIf="index === allowedZipCodesControl.controls.length - 1" (click)="addAllowedZipCodes()" class="btn btn-xs d-inline btn-warning remove pd-t-2 pd-b-2"><i-feather [style.height.px]="16" name="plus"></i-feather></button>
                                        </div>
                                        <button *ngIf="!allowedZipCodesControl.controls.length" (click)="addAllowedZipCodes()" class="btn btn-xs d-inline btn-warning remove pd-t-2 pd-b-2"><i-feather [style.height.px]="16" name="plus"></i-feather></button>

                                    </ng-container>
                                </div>
                            </div>

                            <div id="input-disallowed-zip" class="form-group row zip-codes">
                                <label class="col-2" for="code">{{'disallowedZipCodes' | translate}}:</label>
                                <div class="col-10">
                                    <ng-container formArrayName="disallowedZipCodes">
                                        <div *ngFor="let control of disallowedZipCodesControl.controls; let index=index" class="form-horizontal">
                                            <input [formControlName]="index" [dropSpecialCharacters]="false" mask="99-999" class="form-control" />
                                            <button (click)="removeDisallowedZipCodes(index)" class="btn btn-xs d-inline btn-white remove pd-t-2 pd-b-2"><i-feather [style.height.px]="16" name="trash-2"></i-feather></button>
                                            <button *ngIf="index === disallowedZipCodesControl.controls.length - 1" (click)="addDisallowedZipCodes()" class="btn btn-xs d-inline btn-warning remove pd-t-2 pd-b-2"><i-feather [style.height.px]="16" name="plus"></i-feather></button>
                                        </div>
                                        <button *ngIf="!disallowedZipCodesControl.controls.length" (click)="addDisallowedZipCodes()" class="btn btn-xs d-inline btn-warning remove pd-t-2 pd-b-2"><i-feather [style.height.px]="16" name="plus"></i-feather></button>

                                    </ng-container>
                                </div>
                            </div>
        
                            <ng-container formGroupName="locationAdditional">
                                <label class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{'Address information' | translate}}</label>
    
                                <ng-container *ngFor="let item of additionalKeys">

                                    <div *ngIf="form.get('locationAdditional').get(item)" id="input-{{item}}" class="form-group row">
                                        <ng-container *ngIf="item != 'lift' && item != 'floor'">
                                            <label class="col-2" [for]="item">{{item.toUpperCase() | translate}}:</label>
                                            <div class="col-10">
        
                                                <input *ngIf="item === 'contactPhoneNO'" [id]="item" prefix="+48 " mask="000-000-000" [formControlName]="item" class="form-control" />
                                                <input *ngIf="item !== 'contactPhoneNO'" [id]="item" [formControlName]="item" class="form-control" />
        
                                                <div *ngIf="form.get('locationAdditional').get(item)?.hasError('required') && form.get('locationAdditional').get(item)?.touched" class="error-color">
                                                    {{ "The field is required" | translate }}!
                                                </div>
                                                <div *ngIf="form.get('locationAdditional').get(item)?.hasError('pattern') && form.get('locationAdditional').get(item)?.touched" class="error-color">
                                                    {{ "Above email address is incorrect" | translate }}!
                                                </div>
                                                <div *ngIf="item === 'contactPhoneNO' && form.get('locationAdditional').get('contactPhoneNO')?.hasError('Mask error') && form.get('locationAdditional').get('contactPhoneNO')?.touched" class="error-color">
                                                    {{ "Above phone number you entered is invalid" | translate }}!
                                                </div>
                                                <div *ngIf="form.get('locationAdditional').get(item)?.hasError('maxlength') && form.get('locationAdditional').get(item)?.touched" class="error-color">
                                                    {{ "The field value is too long" | translate }}!
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <div id="input-socialNumberType" class="form-group row">
                                    <label class="col-2" for="socialNumberType">{{'Lift' | translate}}:</label>
                                    <div class="col-10">
                                        <select id="lift" class="custom-select" formControlName="lift">
                                            <option [value]="true">{{'Yes' | translate}}</option>
                                            <option [value]="false">{{'No' | translate}}</option>
                                        </select>
        
                                        <div *ngIf="form.get('locationAdditional').get('lift')?.hasError('required') && form.get('locationAdditional').get('lift')?.touched" class="error-color">
                                                {{ "Lift field is required" | translate }}!
                                        </div>
                                    </div>
                                </div>   
                                <div id="input-floor" class="form-group row">
                                    <label class="col-2" for="floor">{{'Floor'.toUpperCase() | translate}}:</label>
                                    <div class="col-10">
                                        <input id="floor" formControlName="floor" type="number" class="form-control" />
                                        <div *ngIf="form.get('locationAdditional').get('floor')?.hasError('required') && form.get('locationAdditional').get('floor')?.touched" class="error-color">
                                            {{ "The field is required" | translate }}!
                                        </div>
                                    </div>
                                </div>
    
                            </ng-container>
    
                            <ng-container formGroupName="locationCoordinates">
                                <div id="btn-geocode" class="row">
                                    <div class="d-block col-10 offset-2">
                                        <label class="d-block tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-r-20">{{'Depot coordinates' | translate}}</label>
                                        <button class="btn btn-sm btn-white btn-geocode" (click)="getCoordinates()">{{'Geocode address' | translate}}</button>
                                    </div>
                                </div>
                                
                                <div id="map-geocoded" class="row">
                                    <div class="col-10 offset-2 mg-t-20 mg-b-20">
                                        <gmap *ngIf="coordinatesRaw" (coords)="changeCoordinates($event)" [locations]="coordinatesRaw" [mapProperties]="mapProperties" [mapOption]="mapOption"></gmap>
                                    </div>
                                </div>
                                <div id="input-{{item}}" *ngFor="let item of coordinatesKeys" class="form-group row">
                                    <label class="col-2" for="code">{{item.toUpperCase() | translate}}:</label>
                                    <div class="col-10">
                                        <input [id]="item" [formControlName]="item" class="form-control" />
                                        <div *ngIf="form.get('locationCoordinates').get(item)?.hasError('required') && form.get('locationCoordinates').get(item)?.touched" class="error-color">
                                            {{ "The field is required" | translate }}!
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container formGroupName="testLocations">
                                <div class="row">
                                    <div class="d-block col-10 offset-2">
                                        <label class="d-block tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-r-20">{{'Location to test booking availability (click on the map)' | translate}}</label>
                                    </div>
                                </div>
                                
                                <div id="map-markers" class="row">
                                    <div class="col-10 offset-2 mg-t-20 mg-b-20">

                                        <agm-map #map
                                            [latitude]="coordinatesRaw.lat"
                                            [longitude]="coordinatesRaw.lng"
                                            [zoomControl]="true"
                                            [zoom]="10"
                                            [mapTypeControl]="true"
                                            [usePanning]="true"
                                            (mapClick)="placeMarker($event)"
                                            [fitBounds]="bounds">

                                            <ng-container *ngFor="let marker of testLocationsControl.value; let index = index;">
                                                <agm-marker [latitude]="marker.lat" [longitude]="marker.lng">
                                                    <agm-info-window>
                                                        <button (click)="removeMarker(marker, index)" class="btn btn-xs btn-white">Usuń marker</button>
                                                    </agm-info-window>
                                                </agm-marker>
                                            </ng-container>
                                        </agm-map>
                                    </div>
                                </div>
                            

                            </ng-container>

                            <div *ngIf="formErrorsMessages && formErrorsMessages.length" class="form-group row">
                                <div class="col-10 offset-2">
                                    <p class="error-color">{{ 'Something has wrong! Please correct form' | translate }}:</p>
                                    <ul>
                                        <li *ngFor="let error of formErrorsMessages" class="error-color">
                                            <span *ngIf="error.value">{{ 'Field' | translate }}</span>
                                            <strong>{{ error.field | translate }}</strong> -->
                                            <span>{{ error.value | translate }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
    
                            <div class="form-group row justify-content-end">
                                <div class="col-2">
                                    <button id="btn-submit" (click)="onSubmit()" [disabled]="!form.valid || submitted"
                                        class="btn btn-sm pd-x-15 btn-submit btn-uppercase mg-l-5 btn-brand-01 w-100">
    
                                        <span *ngIf="!submitted">{{ "Save" | translate }}</span>
    
                                        <ng-container *ngIf="submitted">
                                            <div class="load-spinner text-center">
                                                <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                            </div>
                                        </ng-container>
    
                                    </button>
                                </div>
                            </div>
    
                        </form>
                    </div>
                </div>
            
        </div>
    </layout-default>
    
    
    <!-- {{'Entity with given code exist' | translate}} -->

    <!-- {{'tooltips.depot.labels.code' | translate -->
