<layout-default>
    <div header>
        <div toolbar>
            <h4><i-feather name="align-justify"></i-feather> {{'Booking Availability' | translate}}</h4>
        </div>
        <div toolbar>
            <ng-datepicker id="booking-availability-datepicker" *ngIf="locale" class="datepicker-input" [(ngModel)]="date" [options]="datePickerOptions"></ng-datepicker>
        </div>
        <hr>
    </div>
    <div content>
        <app-booking-availability-content [date]="_date"></app-booking-availability-content>
    </div>
</layout-default>
